<i18n locale="th" lang="yaml" >
tgd1x.repair.checklist.pass : "ผ่าน"
tgd1x.repair.checklist.fail : "Fail"

tgd1x.repair.inspection.power_board.label : "บอร์ดกระจายไฟ"
tgd1x.repair.inspection.power_board.model : "รุ่นของบอร์ดกระจายไฟ"
tgd1x.repair.inspection.power_board.cable_status : "สายไฟสภาพปกติ"
tgd1x.repair.inspection.power_board.connector_status : "ขั้ว Connector ไม่เลื่อนออกทุกจุด"
tgd1x.repair.inspection.power_board.output.50v : "ไฟ 50V"
tgd1x.repair.inspection.power_board.output.12v : "ไฟ 12V"
tgd1x.repair.inspection.power_board.output.5v : "ไฟ 5V"

tgd1x.repair.inspection.laser.label : "เลเซอร์"
tgd1x.repair.inspection.laser.cleansing : "ทำความสะอาดหน้าเลเซอร์"
tgd1x.repair.inspection.laser.up_floor_value : "ค่าเลเซอร์เมื่อยกขึ้นเหนือพื้น(ระดับเอว)"
tgd1x.repair.inspection.laser.up_floor_value.laser1 : "Laser1"
tgd1x.repair.inspection.laser.up_floor_value.laser2 : "Laser2"

tgd1x.repair.inspection.general.label : "ทดสอบทั่วไป (Mission)"
tgd1x.repair.inspection.general.battery_value : "ค่าแบตเตอรี่ที่วัดได้"
tgd1x.repair.inspection.general.calibrate_compass : "คาริเบรตเข็มทิศ"
tgd1x.repair.inspection.general.flight_mode_checking : "เช็คโหมดการบิน"
tgd1x.repair.inspection.general.compass_value.x : "X"
tgd1x.repair.inspection.general.compass_value.y : "Y"
tgd1x.repair.inspection.general.compass_value.z : "Z"
tgd1x.repair.inspection.general.flight_gps_status : "ค่า GPS รับสัญญาณได้"
tgd1x.repair.inspection.general.hdop_minimum_value : "HDOP ต่ำสุด"
tgd1x.repair.inspection.general.altitude_checking : "ตรวจสอบเลเซอร์วัดความสูงสามารถอ่านค่าได้"

tgd1x.repair.inspection.camera_fpv.label : "กล้องและจอ FPV"
tgd1x.repair.inspection.camera_fpv.receiver_channel_status : "กดจูนค่าช่องรับสัญญาณ 45"
tgd1x.repair.inspection.camera_fpv.camera_switching : "สลับกล้องหน้า/กล่องล่าง"
tgd1x.repair.inspection.camera_fpv.long_distance_signal_quality : "ทดลองบินไกลๆ เพื่อทดสอบการรับสัญญาณภาพ"

tgd1x.repair.inspection.pump.label : "ปั๊ม"
tgd1x.repair.inspection.pump.description : "ทดลองปั๊มอัตโนมัติด้วย HGMC"
tgd1x.repair.inspection.pump.pwm_on : "PWM On"
tgd1x.repair.inspection.pump.pwm_off : "PWM Off"
tgd1x.repair.inspection.pump.on_period : "On period"
tgd1x.repair.inspection.pump.off_period : "Off period"
tgd1x.repair.inspection.pump.model : "รุ่นปั๊ม"
tgd1x.repair.inspection.pump.num_nozzles : "จำนวนหัวฉีด"
tgd1x.repair.inspection.pump.pressure_test : "แรงดัน"
tgd1x.repair.inspection.pump.flow_rate : "วัดอัตราการไหล"

tgd1x.repair.inspection.remark_rich.label : "อื่น ๆ"
tgd1x.repair.inspection.remark_rich.placeholder : "การตรวจสอบอื่น ๆ เช่น มีการเพิ่มกันชน และต้องตรวจสอบกันชนเป็นพิเศษ"
</i18n>

<template>
	<div :class="containerCssClasses">
		<div class="repair-checklist-block">
			<div class="checklist-row checklist-row-title checklist-row-border">
				<label class="title">
					<span class="topic_no">1</span> {{$t('tgd1x.repair.inspection.power_board.label')}}
				</label>
				<div v-trim class="checklist-check">
					<label class="ant-radio-wrapper">{{$t('common.yes')}}</label>
					<label class="ant-radio-wrapper">{{$t('common.no')}}</label>
				</div>
			</div>
			<ServiceChecklistRow v-model="checklistData.power_board.cable_status"
			class="checklist-row-border"
			:can-update="canUpdate"
			:label="$t('tgd1x.repair.inspection.power_board.cable_status')"/>
			<ServiceChecklistRow v-model="checklistData.power_board.connector_status"
				class="checklist-row-border"
				:can-update="canUpdate"
				:label="$t('tgd1x.repair.inspection.power_board.connector_status')"/>
			<div class="checklist-row checklist-row-border">
				<a-checkbox
					:checked="checklistData.power_board.output_50v_status"
					:tab-index="canUpdate ? 0 : -1"
					@change="handleCheckbox($event,'power_board.output_50v_status')">
					{{$t('tgd1x.repair.inspection.power_board.output.50v')}}
				</a-checkbox>

				<a-checkbox
					:checked="checklistData.power_board.output_12v_status"
					:tab-index="canUpdate ? 0 : -1"
					@change="handleCheckbox($event,'power_board.output_12v_status')">
					{{$t('tgd1x.repair.inspection.power_board.output.12v')}}
				</a-checkbox>

				<a-checkbox
					:checked="checklistData.power_board.output_5v_status"
					:tab-index="canUpdate ? 0 : -1"
					@change="handleCheckbox($event,'power_board.output_5v_status')">
					{{$t('tgd1x.repair.inspection.power_board.output.5v')}}
				</a-checkbox>
			</div>
		</div>

		<div class="repair-checklist-block">
			<div class="checklist-row checklist-row-title checklist-row-border">
				<label class="title">
					<span class="topic_no">2</span> {{$t('tgd1x.repair.inspection.laser.label')}}
				</label>
				<div v-trim class="checklist-check">
					<label class="ant-radio-wrapper">{{$t('common.yes')}}</label>
					<label class="ant-radio-wrapper">{{$t('common.no')}}</label>
				</div>
			</div>
			<ServiceChecklistRow v-model="checklistData.laser.cleansing"
				class="checklist-row-border"
				:can-update="canUpdate"
				:label="$t('tgd1x.repair.inspection.laser.cleansing')"/>
			<div class="checklist-row checklist-row-border">
				<label>
					{{$t('tgd1x.repair.inspection.laser.up_floor_value')}}
				</label>
				<div class="checklist-check">
					<label>{{$t('tgd1x.repair.inspection.laser.up_floor_value.laser1')}}</label>
					<a-input
						v-if="canUpdate"
						v-model="checklistData.laser.up_floor_value.laser1" class="repair-checklist-input"
						size="small" type="text"/>
					<label v-else class="text-info">
						: {{checklistData.laser.up_floor_value.laser1 | emptyCheck}}
					</label>
				</div>
			</div>
			<div class="checklist-row checklist-row-border">
				<div class="checklist-check">
					<label>{{$t('tgd1x.repair.inspection.laser.up_floor_value.laser2')}}</label>
					<a-input
						v-if="canUpdate"
						v-model="checklistData.laser.up_floor_value.laser2" class="repair-checklist-input"
						size="small" type="text"/>
					<label v-else class="text-info">
						: {{checklistData.laser.up_floor_value.laser2 | emptyCheck}}
					</label>
				</div>
			</div>
		</div>

		<div class="repair-checklist-block">
			<div class="checklist-row checklist-row-title checklist-row-border">
				<label class="title">
					<span class="topic_no">3</span> {{$t('tgd1x.repair.inspection.general.label')}}
				</label>
				<div v-trim class="checklist-check">
					<label class="ant-radio-wrapper">{{$t('tgd1x.repair.checklist.pass')}}</label>
					<label class="ant-radio-wrapper">{{$t('tgd1x.repair.checklist.fail')}}</label>
				</div>

			</div>
			<div class="checklist-row checklist-row-border">
				<label>{{$t('tgd1x.repair.inspection.general.battery_value')}}</label>
				<label v-if="!canUpdate" class="text-info">
						: {{checklistData.general.battery_value | emptyCheck}}
					</label>
				<div v-else class="checklist-check">
					<a-input
						v-model="checklistData.general.battery_value" class="repair-checklist-input"
						size="small" type="text" style="width:120px"/>
				</div>
			</div>
			<ServiceChecklistRow v-model="checklistData.general.calibrate_compass"
				class="checklist-row-border"
				:can-update="canUpdate"
				:label="$t('tgd1x.repair.inspection.general.calibrate_compass')"/>
			<ServiceChecklistRow v-model="checklistData.general.flight_mode_checking"
				class="checklist-row-border"
				:can-update="canUpdate"
				:label="$t('tgd1x.repair.inspection.general.flight_mode_checking')"/>

			<div class="checklist-row checklist-row-border checklist-row-cols">
				<div>
					<label>{{$t('tgd1x.repair.inspection.general.compass_value.x')}}</label>
					<a-input
						v-if="canUpdate"
						v-model="checklistData.general.compass_value.x" class="repair-checklist-input wide"
						size="small" type="text"/>
					<label v-else class="text-info">
						: {{checklistData.general.compass_value.x | emptyCheck}}
					</label>
				</div>
				<div>
					<label>{{$t('tgd1x.repair.inspection.general.compass_value.y')}}</label>
					<a-input
						v-if="canUpdate"
						v-model="checklistData.general.compass_value.y" class="repair-checklist-input wide"
						size="small" type="text"/>
					<label v-else class="text-info">
						: {{checklistData.general.compass_value.y | emptyCheck}}
					</label>
				</div>
				<div>
					<label>{{$t('tgd1x.repair.inspection.general.compass_value.z')}}</label>
					<a-input
						v-if="canUpdate"
						v-model="checklistData.general.compass_value.z" class="repair-checklist-input wide"
						size="small" type="text"/>
					<label v-else class="text-info">
						: {{checklistData.general.compass_value.z | emptyCheck}}
					</label>
				</div>
			</div>
			<ServiceChecklistRow v-model="checklistData.general.gps_status"
				class="checklist-row-border"
				:can-update="canUpdate"
				:label="$t('tgd1x.repair.inspection.general.flight_gps_status')"/>

			<div class="checklist-row checklist-row-border">
				<label>{{$t('tgd1x.repair.inspection.general.hdop_minimum_value')}}</label>
				<label v-if="!canUpdate" class="text-info">
						: {{checklistData.general.hdop_minimum_value | emptyCheck}}
					</label>
				<div v-else class="checklist-check">
					<a-input
						v-model="checklistData.general.hdop_minimum_value" class="repair-checklist-input wide"
						size="small" type="text"/>
				</div>
			</div>
			<ServiceChecklistRow v-model="checklistData.general.altitude_checking"
				class="checklist-row-border"
				:can-update="canUpdate"
				:label="$t('tgd1x.repair.inspection.general.altitude_checking')"/>
		</div>

		<div class="repair-checklist-block">
			<div class="checklist-row checklist-row-title checklist-row-border">
				<label class="title">
					<span class="topic_no">4</span> {{$t('tgd1x.repair.inspection.camera_fpv.label')}}
				</label>
				<div v-trim class="checklist-check">
					<label class="ant-radio-wrapper">{{$t('tgd1x.repair.checklist.pass')}}</label>
					<label class="ant-radio-wrapper">{{$t('tgd1x.repair.checklist.fail')}}</label>
				</div>
			</div>
			<ServiceChecklistRow v-model="checklistData.camera_fpv.receiver_channel_status"
				class="checklist-row-border"
				:can-update="canUpdate"
				:label="$t('tgd1x.repair.inspection.camera_fpv.receiver_channel_status')"/>
			<ServiceChecklistRow v-model="checklistData.camera_fpv.camera_switching"
				class="checklist-row-border"
				:can-update="canUpdate"
				:label="$t('tgd1x.repair.inspection.camera_fpv.camera_switching')"/>
			<ServiceChecklistRow v-model="checklistData.camera_fpv.long_distance_signal_quality"
				class="checklist-row-border"
				:can-update="canUpdate"
				:label="$t('tgd1x.repair.inspection.camera_fpv.long_distance_signal_quality')"/>
		</div>

		<div class="repair-checklist-block">
			<div class="checklist-row checklist-row-title checklist-row-border">
				<label class="title">
					<span class="topic_no">5</span> {{$t('tgd1x.repair.inspection.pump.label')}}
				</label>
			</div>
			<div class="checklist-row checklist-row-description">
				{{$t('tgd1x.repair.inspection.pump.description')}}
			</div>

			<div class="checklist-row checklist-row-border checklist-row-cols">
				<div>
					<label>{{$t('tgd1x.repair.inspection.pump.pwm_on')}}</label>
					<a-input
						v-if="canUpdate"
						v-model="checklistData.pump.pwm_on" class="repair-checklist-input wide"
						size="small" type="text"/>
					<label v-else class="text-info">
						: {{checklistData.pump.pwm_on | emptyCheck}}
					</label>
				</div>

				<div>
					<label>{{$t('tgd1x.repair.inspection.pump.pwm_off')}}</label>
					<a-input
						v-if="canUpdate"
						v-model="checklistData.pump.pwm_off" class="repair-checklist-input wide"
						size="small" type="text"/>
					<label v-else class="text-info">
						: {{checklistData.pump.pwm_off | emptyCheck}}
					</label>
				</div>
			</div>

			<div class="checklist-row checklist-row-border checklist-row-cols">
				<div>
					<label>{{$t('tgd1x.repair.inspection.pump.on_period')}}</label>
					<a-input
						v-if="canUpdate"
						v-model="checklistData.pump.on_period" class="repair-checklist-input wide"
						size="small" type="text"/>
					<label v-else class="text-info">
						: {{checklistData.pump.on_period | emptyCheck}}
					</label>
				</div>

				<div>
					<label>{{$t('tgd1x.repair.inspection.pump.off_period')}}</label>
					<a-input
						v-if="canUpdate"
						v-model="checklistData.pump.off_period" class="repair-checklist-input wide"
						size="small" type="text"/>
					<label v-else class="text-info">
						: {{checklistData.pump.off_period | emptyCheck}}
					</label>
				</div>
			</div>

			<div class="checklist-row checklist-row-border checklist-row-cols">
				<div>
					<label>{{$t('tgd1x.repair.inspection.pump.model')}}</label>
					<a-input
						v-if="canUpdate"
						v-model="checklistData.pump.model" class="repair-checklist-input wide"
						size="small" type="text"/>
					<label v-else class="text-info">
						: {{checklistData.pump.model | emptyCheck}}
					</label>
				</div>

				<div>
					<label>{{$t('tgd1x.repair.inspection.pump.num_nozzles')}}</label>
					<a-input
						v-if="canUpdate"
						v-model="checklistData.pump.num_nozzles" class="repair-checklist-input wide"
						size="small" type="text"/>
					<label v-else class="text-info">
						: {{checklistData.pump.num_nozzles | emptyCheck}}
					</label>
				</div>
			</div>

			<div class="checklist-row checklist-row-border checklist-row-cols">
				<div>
					<label>{{$t('tgd1x.repair.inspection.pump.pressure_test')}}</label>
					<a-input
						v-if="canUpdate"
						v-model="checklistData.pump.pressure_test" class="repair-checklist-input wide"
						size="small" type="text"/>
					<label v-else class="text-info">
						: {{checklistData.pump.pressure_test | emptyCheck}}
					</label>
				</div>

				<div>
					<label>{{$t('tgd1x.repair.inspection.pump.flow_rate')}}</label>
					<a-input
						v-if="canUpdate"
						v-model="checklistData.pump.flow_rate" class="repair-checklist-input wide"
						size="small" type="text"/>
					<label v-else class="text-info">
						: {{checklistData.pump.flow_rate | emptyCheck}}
					</label>
				</div>
			</div>
		</div>

		<div class="repair-checklist-block repair-remark-block">
			<div class="checklist-row checklist-row-title checklist-row-border">
				<label class="title">
					<span class="topic_no">6</span> {{$t('tgd1x.repair.inspection.remark_rich.label')}}
				</label>
			</div>
			<div class="repair-remark">
				<RichTextInput v-if="canUpdate" ref="richTextRef"
					v-model="checklistData.remark_rich"
					:placeholder="$t('tgd1x.repair.inspection.remark_rich.placeholder')"/>
				<QuillViewer v-else
					:content="checklistData.remark_rich"
					:empty-message="$t('common.unspecify')"/>
			</div>
		</div>

	</div>
</template>

<script>
import {Checkbox} from "ant-design-vue"
import ServiceChecklistRow from "@components/service/ServiceChecklistRow.vue"
import RichTextInput from "@components/input/RichTextInput.vue"
import QuillViewer from "@components/common/QuillViewer.vue"
import {replaceDeepProperty,copyDeep} from "@utils/objectUtil"
import _set from "lodash/set"


function _defaultChecklistData() {
	return {
		power_board : {
			model : '',
			cable_status : undefined,
			connector_status : undefined,
			output_50v_status : undefined,
			output_12v_status : undefined,
			output_5v_status : undefined,
		} ,
		laser : {
			cleansing : undefined,
			up_floor_value : {
				laser1 : '' ,
				laser2 : '',
			}
		} ,
		general : {
			battery_value : '',
			calibrate_compass : undefined ,
			flight_mode_checking : undefined,
			compass_value : {
				x : '',
				y : '' ,
				z : '',
			},
			gps_status : undefined,
			hdop_minimum_value : '',
			altitude_checking : undefined,
		} ,
		camera_fpv : {
			receiver_channel_status : undefined,
			camera_switching : undefined,
			long_distance_signal_quality : undefined,
		} ,
		pump : {
			pwm_on : '',
			pwm_off : '' ,
			on_period : '',
			off_period : '',
			model : '',
			num_nozzles : '',
			pressure_test : '',
			flow_rate : '',
		} ,
		remark_rich : '',
	}
}
export default {
	components :  {
		ServiceChecklistRow ,
		"a-checkbox" : Checkbox, RichTextInput, QuillViewer
	} ,
	props : {
		service : {
			type : null,
			default : () => []
		} ,
		canUpdate : {
			type : Boolean,
			default : false,
		}
	} ,
	data() {
		return {
			checklistData : _defaultChecklistData()
		}
	} ,
	computed : {
		containerCssClasses() {
			return [
				'repair-inspection-checklist' ,
				{
					'checklist-readonly' : !this.canUpdate
				}
			]
		} ,
		checkOptions() {
			return [
				{'value' : true} ,
				{'value' : false}
			]
		} ,
	} ,
	methods : {
		handleCheckbox(event,attribute) {
			if (this.canUpdate)
				_set(this.checklistData,attribute,event.target.checked)
		} ,
		setData(data) {
			this.checklistData = _defaultChecklistData()
			if (data) {
				const remark = data.remark_rich
				delete data.remark_rich
				replaceDeepProperty(this.checklistData,data)
				if (this.$refs.richTextRef)
					this.$refs.richTextRef.setContent(remark)
				else
					this.checklistData.remark_rich = remark
			}
		} ,
		getData() {
			return copyDeep(this.checklistData)
		} ,
	}
}
</script>

<style lang="less" scoped>
	.repair-checklist-input {
		width : 60px;
		margin-right : 4px;
		font-size : 0.9em;
		&.wide {
			width : 100px;
		}
	}
	.repair-inspection-checklist {
		display : flex;
		align-items: stretch;
		flex-wrap: wrap;
		justify-content: flex-start;

	}
	.repair-checklist-block {
		flex-grow : 1;
		flex-basis: 325px;
		min-width : 325px;
		max-width : 400px;
		margin-bottom : 24px;
		padding-right : 16px;

		.mobile  & {
			max-width: 100%;
			padding-right : 0;
			flex-basis: 275px;
			min-width : 275px;
		}

		&.repair-remark-block {
			max-width : 800px;
			min-height : 0;
		}
	}
	.repair-remark {
		margin-top : 4px;
	}

</style>
