<i18n locale="th" lang="yaml" >
service_repair.section.event : "รายละเอียดปัญหา"
service_repair.section.repair : "การซ่อม"
service_repair.section.result : "ผลการซ่อม"
service_repair.section.parts : "รายการอะไหล่งานซ่อม"
service_repair.section.inspection : "ข้อมูลตรวจสอบหลังซ่อม"
</i18n>

<template>
	<div class="repair-container">
		<ErrorMessagePane :error="error" :only-model-keys="['job_repair']"/>

		<div class="repair-section-container repair-result-section">
			<div class="repair-section-title">
				{{$t('service_repair.section.result')}}
			</div>
			<ServiceRepairResultInput ref="repairResultInputRef" class="repair-subsection-container"
				:can-update="canUpdate" :service="service"/>
		</div>

		<div class="repair-section-container">
			<div class="repair-section-title">
				{{$t('service_repair.section.event')}}
			</div>
			<ServiceRepairInput ref="repairInputRef" class="repair-subsection-container"
				:disable-mode="true" :can-update="canUpdate" :service="service"/>
		</div>

		<div class="repair-section-container">
			<div class="repair-section-title">
				{{$t('service_repair.section.repair')}}
			</div>
			<ServiceRepairDetailInput ref="repairDetailInputRef" class="repair-subsection-container"
				:can-update="canUpdate" :service="service"/>
		</div>

		<div v-show="showParts" class="repair-section-container">
			<div class="repair-section-title">
				{{$t('service_repair.section.parts')}}
			</div>
			<ErrorMessagePane :error="error" :only-model-keys="['service_parts_repair']"/>
			<ServicePartList ref="repairPartsRef" :service="service"
				:readonly="!canUpdate || !canUpdatePart"
				:can-modify-row="canUpdate && canUpdatePart"
				mode="repair"/>
		</div>

		<div v-if="!service.is_device_only" class="repair-section-container">
			<div class="repair-section-title">
				{{$t('service_repair.section.inspection')}}
			</div>

			<ServiceRepairInspection ref="inspectionRef" class="repair-subsection-container"
				:can-update="canUpdate" :service="service" />
		</div>
	</div>
</template>

<script>
import ServiceRepairInput from "@components/service/ServiceRepairInput.vue"
import ServiceRepairDetailInput from "@components/service/ServiceRepairDetailInput.vue"
import ServiceRepairResultInput from "@components/service/ServiceRepairResultInput.vue"
import ServiceRepairInspection from "@components/service/ServiceRepairInspection.vue"
import ErrorMessagePane from "@components/common/ErrorMessagePane.vue"
import ServicePartList from "@components/service/ServicePartList.vue"
export default {
	components : {
		ServiceRepairInput, ServiceRepairDetailInput , ServiceRepairResultInput,
		ServiceRepairInspection ,
		ServicePartList ,
		ErrorMessagePane ,
	} ,
	props : {
		service : {
			type : null,
			default : () => []
		} ,
		canUpdate : {
			type : Boolean ,
			default : false,
		} ,
		canUpdatePart : {
			type : Boolean ,
			default : true,
		} ,
		error : {
			type : null,
			default : undefined,
		}
	} ,
	data() {
		return {
			noParts : true
		}
	} ,
	computed : {
		showParts() {
			return this.canUpdate || !this.noParts
		}
	} ,
	methods : {
		setData(data,serviceParts) {
			if (!data.id || data.id <= 0)  {
				data = {
					checklist_type : this.service.checklist_type ,
					is_not_inspect : Boolean(this.service.is_device_only)
				}
			}
			this.$refs.repairInputRef.setData(data)
			this.$refs.repairDetailInputRef.setData(data)
			this.$refs.repairResultInputRef.setData(data)
			if (this.$refs.inspectionRef)
				this.$refs.inspectionRef.setData(data)
			if (serviceParts.repair) {
				this.$refs.repairPartsRef.setData(serviceParts.repair)
				this.noParts = serviceParts.repair.length <= 0
			} else {
				this.$refs.repairPartsRef.setData([])
				this.noParts = true
			}
		} ,
		getData() {
			const inspectData = (this.$refs.inspectionRef) ? this.$refs.inspectionRef.getData() : {}
			return {
				... this.$refs.repairInputRef.getData() ,
				... this.$refs.repairDetailInputRef.getData() ,
				... this.$refs.repairResultInputRef.getData() ,
				... inspectData,
			}
		} ,
		getPartData() {
			const partDatas = this.$refs.repairPartsRef.getData()
			return partDatas
		} ,
		validate() {
			return this.$refs.repairPartsRef.validate()
		}
	}
}
</script>

<style lang="less" scoped>
.repair-section-title {
	font-family : @font-family-title;
	text-decoration: underline;
	font-size : 1.1em;
	color : @secondary-color;
	margin-bottom : 12px;
}
.repair-subsection-container {
	padding-left : 24px;
}
.repair-result-section {
	padding : 12px 24px;
	border : 1px solid @success-color;
	background-color : @gray-2;
	border-radius: @border-radius-base;
	margin-bottom : 32px;
	.mobile & {
		padding : 12px;
	}
}
</style>
