<i18n locale="th" lang="yaml" src="@i18n/service/service.th.yaml"></i18n>
<i18n locale="th" lang="yaml" src="@i18n/service/service-job.th.yaml"></i18n>
<i18n locale="th" lang="yaml">
	service_price.total_price : "ราคาสุทธิ"
</i18n>
<template>
	<a-modal class="service-price-list-modal" :width="800" :visible="visible" :footer="null" :mask-closable="false" @cancel="handleCancel">
		<a-spin wrapper-class-name="tunjai-page-loading" :spinning="loading" size="large" :tip="$t('service_price_list.pageLoading.tip')">
			<div id="print-service-price-list">
				<div v-for="(page, pIndex) of pages" :key="page.key" class="page">
					<div class="page-header">
						<img class="hg-logo" src="@assets/images/hiveground_logo.png"/>
						<div class="modal-name">
							<my-page-header
							:title="$t(isDraft ? 'service_price_list.main_title.draft' : 'service_price_list.main_title.receipt')"/>
						</div>
						<div v-if="setFormData" class="service-no">
							<div style="font-size: 13px;" class="service-no-title">{{$t('service_price_list.number_title')}}</div>
							<div style="font-size: 13px;">{{$t('service_price_list.service_number', { serviceNumber: serviceNumber })}}</div>
							<div class="form-detail">
								<span class="service-no-title">{{ $t('service.header.page_form_title') }}</span>
								{{ $t('service.header.page_form_content', { form: setFormData.formCode }) }}
							</div>
							<div class="form-detail">
								<span class="service-no-title">{{ $t('service.header.page_revision_title') }}</span>
								{{ $t('service.header.page_revision_content', { revision: setFormData.revision }) }}
							</div>
							<div class="form-detail">
								<span class="service-no-title">{{ $t('service.header.page_effective_date_title') }}</span>
								{{ $t('service.header.page_effective_date_content', { effectiveDate: setFormData.effectiveDate }) }}
							</div>
						</div>
					</div>
					<div class="service-detail-container">
						<div class="service-col">
							<label>{{ $t('service_price_list.header.company_info') }}</label>
							<div class="service-val">
								<div class="service-val-row">
									{{ displayCompany.name }}
								</div>
								<div class="service-val-row">
									{{
										(displayCompany.address ? displayCompany.address + ' ' : '') +
										displayCompany.province +
										' ' +
										displayCompany.postal_code
									}}
								</div>
								<div v-if="displayCompany.contact_phone" class="service-val-row">
									<PhoneLink :value="displayCompany.contact_phone" />
								</div>
								<div v-if="displayCompany.contact_email" class="service-val-row info">
									{{ displayCompany.contact_email }}
								</div>
							</div>
						</div>
						<div class="service-col">
							<label>{{ $t('service_price_list.header.drone') }}</label>
							<div class="service-val">
								<div class="service-val-row">
									{{ $t('service_price_list.header.drone.serial', { serial: droneSerial }) }}
								</div>
								<div class="service-val-row">
									{{ $t('service_price_list.header.drone.pixhawk', { pixhawk: dronePixhawk }) }}
								</div>
								<div class="service-val-row">
									{{ $t('service_price_list.header.drone.model', { name: droneModelName }) }}
								</div>
								<div class="service-val-row">
										{{ $t('service.header.drone_owner', { name: ownerName }) }}
								</div>
								<div v-if="ownerIdentityNo" class="service-val-row">
									{{ $t('service.header.drone_owner_identity_no', { id: ownerIdentityNo }) }}
								</div>
								<div v-else class="service-val-row">
									{{ $t('service.header.drone_owner_identity_no_blank') }}
								</div>
								<div class="service-val-row">
									<PhoneLink :value="ownerPhone" />
									<span style="margin-left: 8px">{{ $t('common.province') }}{{ ' ' + ownerProvince }}</span>
								</div>
							</div>
						</div>
						<div class="service-col">
							<label>{{ $t('service.field.customer') }}</label>
							<div v-if="isSamePerson" class="service-val">
								<div class="service-val-row">
									{{ ownerName }}
								</div>
							<div v-if="ownerIdentityNo" class="service-val-row">
								{{ $t('service.header.drone_owner_identity_no', { id: ownerIdentityNo }) }}
							</div>
							<div v-else class="service-val-row">
								{{ $t('service.header.drone_owner_identity_no_blank') }}
							</div>
								<div class="service-val-row">
									<PhoneLink :value="ownerPhone" />
									<span style="margin-left: 8px">{{ $t('common.province') }}{{ ' ' + ownerProvince }}</span>
								</div>
							</div>
							<div v-else class="service-val">
								<div class="service-val-row">
									{{ customerName }}
								</div>
								<div class="service-val-row">
									{{ $t('service.header.drone_owner_identity_no_blank')}}
								</div>
								<div class="service-val-row">
									<PhoneLink :value="customerPhone" />
									<span style="margin-left: 8px">{{ $t('common.province') }}{{ ' ' + customerProvince }}</span>
								</div>
							</div>
						</div>
						<div class="service-col">
							<label>{{ $t('service.field.open_date') }}</label>
							<div class="service-val">{{ openDate }}</div>
						</div>
						<div class="service-col">
							<label>{{ $t('service.field.technician') }}</label>
							<div class="service-val">
								<div class="service-val-row">
									<span v-if="technician">
										{{ technician }}
									</span>
									<span v-else>
										{{ $t('common.unspecify') }}
									</span>
								</div>
							</div>
						</div>
						<div class="service-col">
							<label>{{ $t('service.field.open_by') }}</label>
							<div class="service-val">
								{{ openBy }}
							</div>
						</div>
					</div>

					<div v-if="!isDraft && hasClaim && claimStatus !== 'approved' && claimStatus !== 'rejected'" class="claim-in-progress-error">{{$t('service_price_list.claim.error.not_finish')}} </div>
					<a-table v-else bordered :row-key="(record) => record.key" :data-source="page.data" :pagination="false" size="small">
						<a-table-column :title="$t('common.table.column.no')" :width="10" align="center">
							<template slot-scope="record">
								<div>{{ record.sequence }}</div>
							</template>
						</a-table-column>
						<a-table-column :title="$t('service_price_list.field.sku')" :width="80">
							<template slot-scope="record">
								<div :class="!isDraft  ? 'table-colum-bold-item' : ''">{{ record.partSku ? record.partSku : "-" }}</div>
							</template>
						</a-table-column>
						<a-table-column :title="$t('service_price_list.field.name')" :width="isDraft ? 190 : 100">
							<template slot-scope="record">
								<div :class="!isDraft  ? 'table-colum-bold-item' : ''">{{ record.description}}</div>
							</template>
						</a-table-column>
						<a-table-column :title="$t('service_price_list.field.num')" align="center" :width="isDraft ? 40 : 30">
							<template slot-scope="record">
								<div :class="!isDraft  ? 'table-colum-bold-item' : ''">{{ record.partNumChange }}</div>
							</template>
						</a-table-column>
						<a-table-column v-if="!isDraft" :title="$t('service_price_list.field.claim_approved')" align="center" :width="30">
							<template slot-scope="record">
								<div style="white-space: pre-line">{{ record.approvedClaimNum }}</div>
							</template>
						</a-table-column>
						<a-table-column v-if="!isDraft" :title="$t('service_price_list.field.num_pay_after_claim')" align="center" :width="30">
							<template slot-scope="record">
								<div>{{ isNumber(record.partNumChange - record.approvedClaimNum) ? record.partNumChange - record.approvedClaimNum : '-' }}</div>
							</template>
						</a-table-column>
						<a-table-column v-if="!isDraft" :title="$t('service_price_list.field.pos_discount')" align="right" :width="45">
							<template slot-scope="record">
								<div class="table-colum-bold-item">{{ isNumber(record.approvedClaimNum) && isNumber(record.pricePerUnit) ?
									numberFormat(record.approvedClaimNum * record.pricePerUnit) : "-" }}
								</div>
								<div class="table-colum-bold-item">
									{{calculateDiscount(record.partNumChange, record.approvedClaimNum, record.pricePerUnit) }}
								</div>
							</template>
						</a-table-column>
						<a-table-column :title="$t('service_price_list.field.pricePerUnit')" align="right" :width="isDraft ? 50 : 45">
							<template slot-scope="record">
								<div>{{ record.pricePerUnitDisplay }}</div>
							</template>
						</a-table-column>
						<a-table-column  :title="$t('service_price_list.field.price')" align="right" :width="isDraft ? 60 : 50">
							<template slot-scope="record">
								<div style="white-space: pre-line">{{ record.priceDisplay }}</div>
							</template>
						</a-table-column>
						<a-table-column v-if="isDraft" :title="$t('service_price_list.field.claim')" align="right" :width="40">
							<template slot-scope="record">
								<div>{{ record.claimInfo }}</div>
							</template>
						</a-table-column>
					</a-table>
					<div v-if="pIndex === pages.length - 1 && isDraft" class="last-page">
						<div class="total-section">
							<div class="total-section-column">
								<div class="total-section-row">
									<div class="total-section-unit">บาท</div>
									<div :class="{ 'total-section-price': hasClaimPrice, 'total-section-price-claim': !hasClaimPrice }">
										{{ totalPriceBeforeVat }}
									</div>
									<div v-if="hasClaimPrice" class="total-section-dash">-</div>
									<div v-if="hasClaimPrice" class="total-section-price-claim">
										{{ totalPriceBeforeVatMin }}
									</div>
									<div class="total-section-description">ราคาประเมินก่อนรวม vat (7%)</div>
								</div>
								<div class="total-section-row">
									<div class="total-section-unit">บาท</div>
									<div :class="{ 'total-section-price': hasClaimPrice, 'total-section-price-claim': !hasClaimPrice }">
										{{ totalVat }}
									</div>
									<div v-if="hasClaimPrice" class="total-section-dash">-</div>
									<div v-if="hasClaimPrice" class="total-section-price-claim">
										{{ totalVatMin }}
									</div>
									<div class="total-section-description">vat (7%)</div>
								</div>
								<div class="total-section-row">
									<div class="total-section-unit text-bold">บาท</div>
									<div :class="{ 'total-section-price': hasClaimPrice, 'total-section-price-claim': !hasClaimPrice, 'text-bold': true }">
										{{ totalPriceAfterVat }}
									</div>
									<div v-if="hasClaimPrice" class="total-section-dash text-bold">-</div>
									<div v-if="hasClaimPrice" class="total-section-price-claim text-bold">{{ totalPriceAfterVatMin }}</div>
									<div class="total-section-description text-bold">ราคาประเมินรวม vat (7%)</div>
								</div>
							</div>
						</div>
						<div v-if="hasClaimPrice" v-html="$t('servcie_price_list.footer.claim_info')" />
						<div v-html="$t('servcie_price_list.footer.price_info')" />
						<div v-html="$t('servcie_price_list.footer.consent.period_info')" />
						<div v-html="$t('servcie_price_list.footer.consent.change_price_info')" />
						<div v-html="$t('servcie_price_list.footer.consent.insurace_info')" />
						<div v-html="$t('servcie_price_list.footer.consent.agree')" />
						<div v-html="$t('servcie_price_list.footer.consent.disagree')" />
					</div>
					<div v-if="isDraft">
						<div class="signature">
							<div
								class="signature-item"
								v-html="$t('service_price_list.footer.signature', { description: $t('service_price_list.footer.signature.customer') })"/>
							<div
								class="signature-item"
								v-html="$t('service_price_list.footer.signature', { description: $t('service_price_list.footer.signature.quote_by') })"/>
							<div
								class="signature-item"
								v-html="$t('service_price_list.footer.signature', { description: $t('service_price_list.footer.signature.approve_by') })"/>
						</div>
						<div v-if="pIndex === pages.length - 1" class="wrapper-header-service">
							<div class="header-grow"></div>
							<div class="last-page-remark" v-html="$t('service_price_list.footer.remark')"/>
						</div>
						<div v-else>
							<div class="page-divider" />
							<div style="page-break-before: always" align="center">
								<span style="visibility: hidden">-</span>
							</div>
						</div>
					</div>
					<div v-else-if="pIndex < pages.length - 1">
						<div class="page-divider" />
						<div style="page-break-before: always" align="center">
							<span style="visibility: hidden">-</span>
						</div>
					</div>
					<div v-if="!isDraft && !hasClaim" class="claim-remark"> {{$t('service_price_list.claim.warning.no_claim')}} </div>
					<div v-if="isDraft">
						<div>
							<div class="page-divider" />
							<div style="page-break-before: always" align="center">
								<span style="visibility: hidden">-</span>
							</div>
						</div>
						<div class="consent-form-header">
							<br><br>
							<div class="consent-form-title" >
								<div v-html="$t('service_price_list.consent.form.title')"/>
							</div>
						</div>
						<div>
							<div v-html="$t('service_price_list.consent.form', { customer: '........................' })"/>
							<br>
							<div v-html="$t('service_price_list.consent.form.signature')"/>
						</div>
						<div class="wrapper-header-consent">
							<div class="header-grow"></div>
							<div class="last-page-remark" v-html="$t('service_price_list.footer.remark')"/>
						</div>
					</div>
				</div>
				</div>
			<a-button class="print-button" type="info" icon="printer" @click="print">พิมพ์</a-button>
		</a-spin>
	</a-modal>
</template>

<script>
import { mapState } from 'vuex'
import { Table } from 'ant-design-vue'
import PhoneLink from '@components/common/PhoneLink.vue'
import axios from 'axios'
import ApiError from '@utils/errors/ApiError'
import isNaN from "lodash/isNaN"
import { claimInfo, CLAIM_REQUEST_STATUS } from "@utils/claimUtil"
import { findFormData, FORM_CODE } from '@utils/documentUtil'
import {
					getPartsByTypeService,
					calculateTechnicianFeeByTotal,
					calculateVatDisplayNumber,
					formatDisplayNumber
			} from "@utils/serviceUtil"

export default {
	components: {
		PhoneLink,
		'a-table': Table,
		'a-table-column': Table.Column,
	},

	props: {
		isDraft: {
			type: Boolean,
			defaul: false,
		},
	},

	data() {
		return {
			totalPriceWithClaimApproved: 0,
			totalPrice: 0,
			technicianFeeWithClaimApproved: 0,
			totalFee: 0,
			visible: false,
			serviceId: '',
			serviceNumber: '',
			droneSerial: '',
			dronePixhawk: '',
			droneModelName: '',
			customerName: '',
			customerPhone: '',
			customerProvince: '',
			openDate: '',
			openBy: '',
			technician: '',
			repairPartChanges: [],
			pages: [],
			printDate: null,
			hasClaimPrice: false,
			loading: false,
			hasClaim: false,
			claimStatus: '',
			totalPriceBeforeVat: 0,
			totalVat: 0,
			totalPriceAfterVat: 0,
			totalPriceBeforeVatMin: 0,
			totalVatMin: 0,
			totalPriceAfterVatMin: 0,
			remark: '',
			ownerName: '',
			ownerIdentityNo: null,
			ownerPhone: '',
			ownerProvince: '',
			isSamePerson: false,
			formData: {}
		}
	},

	computed: {
		...mapState('user', ['companies']),
		...mapState('auth', ['currentCompany']),
		...mapState('drone', ['manHourPrice']),
		...mapState('form', ['forms']),
		displayCompany() {
			return this.companies.find((c) => c.id == this.currentCompany.id)
		},
		isClaimApproved() {
			return this.claimStatus === CLAIM_REQUEST_STATUS.APPROVED
		},
		setFormData() {
			const formCode = this.isDraft ? FORM_CODE.COST_ESTIMATE : FORM_CODE.PRICE_SUMMARY
			return findFormData(this.forms, formCode)
		}
	},

	methods: {
		numberFormat(value) {
			return formatDisplayNumber(value)
		},
		isNumber(value) {
			return !!value && !isNaN(value)
		},
		calculateDiscount(partNumChange, approvedClaimNum, pricePerUnit) {
			if (!(partNumChange && approvedClaimNum && pricePerUnit)) return "-"

			const totalCost = partNumChange * pricePerUnit
			const claimedCost = (partNumChange - approvedClaimNum) * pricePerUnit
			return `(${((( totalCost - claimedCost) / totalCost) * 100).toFixed(1)}%)`
		},
		_buildPagesFromItems(claims = []) {
			// combine repaire and normal part change list
			const itemList = [
				...this.repairPartChanges,
			]
			// append last element to be a dummy for the total fee row
			itemList.push({
				partNumChange: 1,
				feePerUnit: 0,
				pricePerUnit: 0,
				description: '-',
				canClaim: false,
				sendForClaim: false
			})

			this.pages = []
			const itemPerPage = this.isDraft ? 7 : 9 // we can display longer for the price summary report
			const numPage = Math.ceil(itemList.length / itemPerPage)
			for (let i = 0; i < numPage; i++) {
				const pageData = []

				for (let j = 0; j < itemPerPage; j++) {
					if (i * itemPerPage + j >= itemList.length) break

					const targetPart = itemList[i * itemPerPage + j]

					const claimPartResult = claims.find(p => p.id == targetPart.id)
					const approvedClaimNum = (claimPartResult && claimPartResult.num_approved) ? claimPartResult.num_approved : (targetPart.isRecall ? targetPart.partNumChange : 0)

					// total technician fee

					pageData.push({...targetPart,
						key: 'service_table_' + i + j,
						sequence: i + j + 1,
						priceDisplay: formatDisplayNumber(this.isClaimApproved ? targetPart.priceWithClaimApproved : targetPart.price),
						pricePerUnitDisplay: formatDisplayNumber(targetPart.pricePerUnit),
						approvedClaimNum: approvedClaimNum,
						claimInfo: claimInfo(this, targetPart)
					})
				}
				// total cost
				pageData.push({
					key: 'service_table_total_cost',
					sequence: pageData.length + 1,
					approvedClaimNum: '-',
					partNumChange: '-',
					pricePerUnit: 0,
					priceDisplay: 0,
				})
				this.pages.push({
					key: 'receiptPage_' + i,
					data: pageData,
				})
			}
		},
		_loadClaimData() {
			this.loading = true
			axios.get("/api/services/" + this.serviceId + "/claim-request").then((response) => {
				this.claimStatus = response.data.data.claim_request.status
				const claimData = this.isClaimApproved ? response.data.data.service_parts.repair : []
				this._buildPagesFromItems(claimData)
				this.recalculateClaim()
			}).catch((error) => {
				this.$message.error(ApiError.getDisplayErrorMessage(this,error))
			}).finally(() => {
				this.loading = false
			})
		},
		recalculateClaim() {
			const items = this.pages.map((p) => p.data).flat()
			// remove last item (servcie fee)
			items.splice(items.length - 2, 2)
			// filter only the no-claim item
			// const itemsExcludeClaim = items.filter((i) => !i.sendForClaim)
			const partsWithoutServ = getPartsByTypeService(items)
			const sumTotalTime = partsWithoutServ.reduce((acc, i) => acc + i.totalTime, 0)
			const sumFinalTime = partsWithoutServ.reduce((acc, i) => acc + i.finalApprovedTime, 0)

			this.technicianFee = calculateTechnicianFeeByTotal(sumTotalTime, this.manHourPrice)
			this.technicianFeeWithClaimApproved = calculateTechnicianFeeByTotal(sumFinalTime, this.manHourPrice)

			this.totalPrice = items.reduce((acc, i) => acc + i.price, 0) + this.technicianFee

			this.totalPriceWithClaimApproved = items.reduce((acc, i) => acc + i.priceWithClaimApproved, 0) + this.technicianFeeWithClaimApproved

			this.hasClaimPrice = this.totalPrice !== this.totalPriceWithClaimApproved

			this.hasClaim = !!this.hasClaimPrice

			this.totalPriceBeforeVat = calculateVatDisplayNumber(this.totalPrice)
			this.totalVat = calculateVatDisplayNumber(this.totalPrice, true)
			this.totalPriceAfterVat = formatDisplayNumber(this.totalPrice)
			this.totalPriceBeforeVatMin = calculateVatDisplayNumber(this.totalPriceWithClaimApproved)
			this.totalVatMin =  calculateVatDisplayNumber(this.totalPriceWithClaimApproved, true)
			this.totalPriceAfterVatMin = formatDisplayNumber(this.totalPriceWithClaimApproved)


			const lastPage = this.pages[this.pages.length - 1]
			const secondToLastItem = lastPage.data[lastPage.data.length - 2]
			const lastItem = lastPage.data[lastPage.data.length - 1]

			// update total technician fee
			lastPage.data[lastPage.data.length - 2] = {
				...secondToLastItem,
				partSku: '-',
				pricePerUnitDisplay: '-',
				priceDisplay: this.priceDisplay(this.isDraft),
				claimInfo: '',
				description: this.$t('service_cost_estimate.total_technician_fee')
			}
			// update last page item for total service fee
			lastPage.data[lastPage.data.length - 1] = {
				...lastItem,
				pricePerUnitDisplay: '-',
				priceDisplay: this.isClaimApproved ? formatDisplayNumber(this.totalPriceWithClaimApproved) : formatDisplayNumber(this.totalPrice),
				description: this.$t('service_price.total_price'),
			}
		},
		priceDisplay(isDraft) {
			if (!isDraft) return formatDisplayNumber(this.technicianFeeWithClaimApproved)
			return formatDisplayNumber(this.technicianFee) + (this.hasClaimPrice ? ` (${formatDisplayNumber(this.technicianFeeWithClaimApproved)})` : '')
		},
		openModal(data) {
			const currentDateTime = new Date()

			this.visible = true
			this.serviceId = data.serviceId
			this.serviceNumber = data.serviceNumber
			this.droneSerial = data.droneSerial
			this.dronePixhawk = data.dronePixhawk
			this.droneModelName = data.droneModelName
			this.customerName = data.customerName
			this.customerPhone = data.customerPhone
			this.customerProvince = data.customerProvince
			this.openDate = data.openDate
			this.openBy = data.openBy
			this.technician = data.technician
			this.repairPartChanges = data.repairPartChanges
			this.ownerName = data.owner.name
			this.ownerIdentityNo =  data.owner.identity_no
			this.ownerPhone = data.owner.phone
			this.ownerProvince = data.owner.province
			this.isSamePerson = this.customerName === this.ownerName
			this.printDate = currentDateTime.toLocaleDateString() + ':' + currentDateTime.toLocaleTimeString()
			if (this.isDraft ) {
				this._buildPagesFromItems()
				this.recalculateClaim()
			} else {
				this._loadClaimData()
			}
		},
		handleCancel() {
			this.visible = false
		},
		print() {
			const prtHtml = document.getElementById('print-service-price-list').innerHTML

			// Get all stylesheets HTML
			let stylesHtml = ''
			for (const node of [...document.querySelectorAll('link[rel="stylesheet"], style')]) {
				stylesHtml += node.outerHTML
			}

			// Open the print window
			const WinPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0')

			WinPrint.document.write(`<!DOCTYPE html>
				<html>
  				<head>
    				${stylesHtml}
  				</head>
  				<body>
    				${prtHtml}
  				</body>
				</html>`)

			setTimeout(()=> {
				WinPrint.focus()
				WinPrint.document.close()
				WinPrint.print()
				WinPrint.close()
			}, 2000)
		},
	},
}
</script>

<style lang="less" scoped>
@part-break-screen: @screen-lg;
.service-price-list-modal {
	width: 700px;
	div {
		font-size: 0.7rem;
	}
	.claim-in-progress-error {
		display: flex;
		margin-top: 8px;
		font-size: 1.5em;
		justify-content: center;
		align-items: center;
		font-weight: 600;
		color: @tigerdrone-logo-color;
	}
	.claim-remark {
		display: flex;
		margin-top: 8px;
		font-size: 1.5em;
		justify-content: center;
		align-items: center;
	}
}
.tunjai-page-loading {
	min-height: 300px;

	.ant-spin-blur {
		opacity: 0.4;
	}
	.ant-spin-dot i {
		background-color: @info-color;
	}
	.ant-spin-text {
		font-size: 2em;
		font-family: @font-family-title;
		margin-top: 14px;
		color: @info-color;
	}
}
.info {
	color: @info-color;
}
.page-header {
	display: flex;
	flex-direction: row;
	padding-top: 10px;
	align-items: center;
  justify-content: space-between;
}
.service-no {
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	padding-left: 30px;
}
.service-no-title {
	color: @primary-color;
}
.form-detail {
	font-style: italic;
}
.modal-name {
	justify-content: center;
	align-items: center;
	text-align: center;
}
.hg-logo {
  max-width: 25%;
  height: 40%;
}
.consent-form-header {
	display: flex;
	flex-basis: 100%;
	flex-direction: column;
}
.consent-form-title {
	text-align: center;
  font-family: 'Prompt', Helvetica, Arial, sans-serif;
  font-weight: 600;
  color: #14837B;
}
.consent-form-page-num {
	text-align: right;
}
.service-detail-container {
	position: relative;
	display: flex;
	flex-wrap: wrap;
	background-color: @white;
	margin-bottom: 16px;
	border-radius: @border-radius-base;
	.service-col {
		flex-basis: 50%;
		display: flex;
		flex-wrap: nowrap;
		min-width: 0;
		border: 1px solid @gray-4;
		> label {
			font-family: @font-family-title;
			color: @primary-color;
			min-width: 100px;
			flex-basis: 100px;
			background-color: @gray-3;
			padding: 4px 8px;
		}
		> .service-val {
			flex-grow: 1;
			padding: 4px 8px;
			min-width: 0;
			.service-val-row {
				margin-bottom: 2px;
				&:last-child {
					margin-bottom: 0;
				}
			}
		}
	}
}
.table-colum-bold-item {
	font-weight: 900;
}
.claim-row {
	display: flex;
	flex-direction: row-reverse;

	.claim-checkbox {
		margin-left: 8px;
	}
}
.total-section {
	display: flex;
	flex-direction: row-reverse;
	margin-top: 0px;
	margin-bottom: 16px;

	.total-section-column {
		display: flex;
		flex-direction: column;
		flex-basis: 60%;
		margin-top: 8px;
		margin-bottom: 0px;

		.total-section-row {
			display: flex;
			flex-direction: row-reverse;
			justify-content: right;
			align-items: right;
			margin-top: 8px;

			.total-section-description {
				display: flex;
				flex-basis: 50%;
				position: relative;
				justify-content: right;
				align-items: right;
				margin-right: 8px;
			}

			.total-section-dash {
				display: flex;
				flex-basis: 2%;
				position: relative;
				justify-content: right;
				align-items: right;
				margin-right: 8px;
			}

			.total-section-price-claim {
				display: flex;
				flex-basis: 20%;
				position: relative;
				justify-content: right;
				align-items: right;
				margin-right: 8px;
			}

			.total-section-price {
				display: flex;
				flex-basis: 20%;
				position: relative;
				justify-content: left;
				align-items: left;
				margin-right: 8px;
			}

			.total-section-unit {
				display: flex;
				flex-basis: 8%;
				position: relative;
				justify-content: left;
				align-items: left;
				margin-right: 8px;
			}
		}
	}
}
.signature {
	display: flex;
	position: relative;
	padding: 10px;
	margin-top: 0px;
	margin-bottom: 0px;

	.signature-item {
		display: flex;
		flex-basis: 33%;
		align-items: center;
		flex-direction: column;
		position: relative;
		margin-right: 8px;
	}
}
.last-page-remark {
	display: flex;
	margin-top: 8px;
	justify-content: left;
	align-items: left;
}
.page-divider {
	display: flex;
	padding: 12px 16px;
	border-bottom: 1px dashed @primary-color;
	position: relative;
}
.print-button {
	margin-top: 8px;
}
@media print {
	div {
		font-size: 0.7rem;
	}

	input[type='checkbox'] {
		display: none;
	}

	.page-divider {
		display: none;
	}

	.service-col {
		> label {
			-webkit-print-color-adjust: exact;
		}
	}
}
</style>
