<i18n locale="th" lang="yaml" src="@i18n/service/service-job.th.yaml"></i18n>

<template>
	<div class="page-padding">
		<ServiceSuggestion
			:service="service"
			:current-firmware-id="currentFirmwareId"
			:can-update="canUpdate"
			:has-firmware-already="currentJobFirmware"
			:has-maintenance-already="currentJobMaintenance"/>

		<div class="page-action-right" style="margin-bottom : 12px;">
			<a-button @click="handleReset">
				{{$t('service_job.action.ask_reset')}}
			</a-button>
			<a-button type="primary" class="btn-submit" @click="handleSave">
				{{$t('common.save')}}
			</a-button>

			<div class="service_job-manage-btn">
				<a-dropdown>
					<a-button>{{$t('service_job.manage')}} <a-icon type="down" /></a-button>
					<a-menu slot="overlay" class="service-add-menu" @click="handleServiceAddMenu">
						<a-menu-item v-for="menu in serviceAddMenuItems" v-show="menu.visible" :key="menu.key" :disabled="menu.disabled">
							<a-icon :type="menu.icon"/> {{menu.label}}
						</a-menu-item>
					</a-menu>
				</a-dropdown>
				<a-button icon="printer" @click="handlePriceListandPriceSummary(service.status)">
					{{ service.status === 'new' || service.status === 'in_progress' ? $t('service_job.price_list.label') : $t('service_job.manage.job_price_summary.show')}}
				</a-button>
			</div>
		</div>

		<ErrorMessagePane
			:error="globalError"/>


		<a-collapse v-show="!loading" v-model="activeKeys" class="service_job-collapse">
			<a-collapse-panel v-if="currentJobRepair" key="repair"
				:force-render="true"
				:header="$t('service_job.repair')"
				class="service_job-repair-pane">
				<a-icon v-if="canUpdateJob" slot="extra" type="delete"
				 theme="filled" :title="$t('service_job.manage.job_repair.remove')"
					@click="handleServiceRemove('job_repair')" />
				<ServiceRepair
					ref="repairRef"
					:error="componentError"
					:service="service"
					:can-update="canUpdateJob"
					:can-update-part="canUpdatePart"/>
			</a-collapse-panel>
			<a-collapse-panel v-if="currentJobFirmware" key="firmware"
				:force-render="true"
				:header="$t('service_job.firmware')"
				class="service_job-firmware-pane">
				<a-icon v-if="canUpdateJob" slot="extra" type="delete"
					theme="filled" :title="$t('service_job.manage.job_firmware.remove')"
					@click="handleServiceRemove('job_firmware')" />
				<ServiceFirmware
					ref="firmwareRef"
					:service="service"
					:current-firmware-id="currentFirmwareId"
					:error="componentError"
					:can-update="canUpdateJob"/>
			</a-collapse-panel>
			<a-collapse-panel v-if="currentJobMaintenance" key="maintenance"
				:force-render="true"
				:header="$t('service_job.maintenance')"
				class="service_job-maintenance-pane">
				<a-icon v-if="canUpdateJob" slot="extra" type="delete"
					theme="filled" :title="$t('service_job.manage.job_maintenance.remove')"
					@click="handleServiceRemove('job_maintenance')" />
				Maintenance
			</a-collapse-panel>
			<a-collapse-panel key="files"
				:force-render="true"
				:header="$t('service_job.files')"
				class="service_job-files-pane">
				<div class="files-upload">
					<div class="files-component">
						<ServiceFilesPanel
							ref="serviceFilesRef"
							:error="componentError"
							:service="service"
							:label="$t('service_job.images.label')"
							:others-label="$t('service_job.others.label')"
							:can-update="canUpdateJob" />
					</div>
					<div class="files-component">
						<ServiceFilesPanel
							ref="servicePriceListDocumentFilesRef"
							:error="componentError"
							:service="service"
							:label="$t('service_job.price_list_document_images.label')"
							:others-label="$t('service_job.price_list_document_files.label')"
							:can-update="canUpdateJob"/>
					</div>
					<div class="files-component">
						<ServiceFilesPanel
							ref="serviceConsentDocumentFilesRef"
							:error="componentError"
							:service="service"
							:label="$t('service_job.consent_document_images.label')"
							:others-label="$t('service_job.consent_document_files.label')"
							:can-update="canUpdateJob" />
					</div>
				</div>
			</a-collapse-panel>
		</a-collapse>
		<div v-if="canUpdateJob" class="page-action-right" style="margin-top : 24px;">
			<a-button @click="handleReset">
				{{$t('service_job.action.ask_reset')}}
			</a-button>
			<a-button type="primary" class="btn-submit" @click="handleSave">
				{{$t('common.save')}}
			</a-button>
		</div>
		<ServicePriceListModal
			ref="servicePriceListModalRef"
			:is-draft="isDraft"/>
	</div>
</template>

<script>
import PageMixin from "@mixins/PageMixin.vue"
import ServiceObjectMixin from "@/src/mixins/service/ServiceObjectMixin.vue"
import {Collapse,Dropdown,Menu} from "ant-design-vue"
import ServiceSuggestion from "@components/service/ServiceSuggestion.vue"
import ServiceRepair from "@components/service/ServiceRepair.vue"
import ServiceFirmware from "@components/service/ServiceFirmware.vue"
import ServiceFilesPanel from "@components/service/ServiceFilesPanel.vue"
import ServicePriceListModal from "@components/service/ServicePriceListModal.vue"
import ErrorMessagePane from "@components/common/ErrorMessagePane.vue"
import axios from "axios"
import ModelError from "@utils/errors/ModelError"
import _isEqual from "lodash/isEqual"
import {scrollTopContent} from "@utils/formUtil"
import {mapGetters} from "vuex"
import {PAYMENT_STATUS} from "@utils/serviceUtil"

export default {
	components : {
		ServiceSuggestion, ErrorMessagePane, ServicePriceListModal,
		ServiceRepair,ServiceFirmware , ServiceFilesPanel ,
		"a-collapse" : Collapse , "a-collapse-panel" : Collapse.Panel,
		"a-dropdown" : Dropdown, "a-menu" : Menu, "a-menu-item" : Menu.Item,
	} ,
	mixins : [PageMixin,ServiceObjectMixin] ,
	props : {
		service : {
			type : null,
			default : () => []
		} ,
		otherDetails : {
			type : null,
			default : () => []
		} ,
	} ,
	data() {
		return {
			loading : false,
			activeKeys : [],
			currentServiceId : undefined,
			serviceFiles: [],
			servicePriceListDocumentFiles: [],
			serviceConsentDocumentFiles: [],
			serviceParts : {} ,

			jobRepair : {} ,
			jobFirmware : {} ,
			jobMaintenance : {} ,
			currentFirmwareId : undefined,

			currentJobRepair : false,
			currentJobFirmware : false,
			currentJobMaintenance : false,

			ignorePreventExit : false,
			oldFormData : {} ,

			saveError : undefined,

			isDraft : true,

			payment: {}
		}
	} ,
	computed : {
		...mapGetters('drone',['getLatestFirmwareBySet', 'getPartsInfo']) ,
		canUpdateJob() {
			 return this.canUpdate
		} ,
		canUpdatePart() {
			 return this.payment.status !== PAYMENT_STATUS.STATUS_COMPLETE
		} ,
		needUpdateFirmware() {
			const currentFirmwareId = this.currentFirmwareId ? this.currentFirmwareId : this.service.droneData.firmware.id
			const firmwareSetId = this.service.droneData.firmware.firmware_set_id
			const latestFirmware = this.getLatestFirmwareBySet(firmwareSetId)
			return (latestFirmware !== false && latestFirmware.id !== currentFirmwareId)
		} ,
		serviceAddMenuItems() {
			const menus = [
				{
					key : 'job_repair' ,
					icon : 'plus' ,
					label : this.$t('service_job.manage.job_repair.add') ,
					disabled : this.currentJobRepair ,
					visible : this.canUpdate
				} ,
				{
					key : 'job_firmware' ,
					icon : 'plus' ,
					label : this.$t('service_job.manage.job_firmware.add') ,
					disabled : this.currentJobFirmware || !this.needUpdateFirmware,
					visible : !this.service.is_device_only && this.canUpdate
				} ,
				{
					key : 'job_maintenance' ,
					icon : 'plus' ,
					label : this.$t('service_job.manage.job_maintenance.add') ,
					disabled : true ,
					visible : !this.service.is_device_only && this.canUpdate
				}
			];
			return menus;
		} ,
		hasJobRepair() {
			return this.service.id > 0 && this.jobRepair.id && this.jobRepair.id > 0
		} ,
		hasJobFirmware() {
			return this.service.id > 0 && this.jobFirmware.id && this.jobFirmware.id > 0
		} ,
		hasJobMaintenance() {
			return this.service.id > 0 && this.jobMaintenance.id && this.jobMaintenance.id > 0
		} ,
		globalError() {
			return this.saveError instanceof ModelError ? undefined : this.saveError
		} ,
		componentError() {
			return this.saveError instanceof ModelError ? this.saveError : undefined
		}
	} ,
	watch : {
		$route() {
			this.fetchJobs()
		}
	} ,
	mounted() {
		this.fetchJobs()
	} ,
	beforeMount() {
		window.addEventListener("beforeunload",this.preventExit)
	} ,
	beforeDestroy() {
		window.removeEventListener("beforeunload",this.preventExit)
	} ,
	methods : {
		async fetchJobs() {
			const serviceId = this.$route.params.id
			if (!serviceId || this.currentServiceId == serviceId) {
				return
			}
			this.loading = true
			try {
				const jobResponse = await axios.get("/api/services/"+serviceId+"/jobs")
				const jobData = jobResponse?.data?.data || {}
				this.serviceFiles = jobData.service_files
				this.servicePriceListDocumentFiles = jobData.service_price_list_document_files
				this.serviceConsentDocumentFiles = jobData.service_consent_document_files
				this.serviceParts = jobData.service_parts
				this.currentFirmwareId = jobData.current_firmware_id
				this.jobRepair = ('job_repair' in jobData) ? jobData.job_repair : {}
				this.jobFirmware = ('job_firmware' in jobData) ? jobData.job_firmware : {}
				this.jobMaintenance = ('job_maintenance' in jobData) ? jobData.job_maintenance : {}
				this.currentServiceId = serviceId
				this.initJobs()
				const paymentResponse = await axios.get("/api/services/"+serviceId+"/payment")
				this.payment = paymentResponse?.data?.data?.payment || {}
			} catch (error) {
				this.fetchError(error)
			} finally {
				this.loading = false
			}
		} ,
		handleServiceAddMenu({key}) {
			switch(key) {
				case 'job_repair' :
					this.currentJobRepair = true
					this.$nextTick(()=>{
						if (this.$refs.repairRef) {
							this.$refs.repairRef.setData({},{})
							this.activeKeys.push('repair')
						}
					})
					break;
				case 'job_maintenance' :
					this.currentJobMaintenance = true
					this.$nextTick(() => {
						// TODO
						this.activeKeys.push('maintenance')
					})
					break;
				case 'job_firmware' :
					this.currentJobFirmware = true
					this.$nextTick(() => {
						if (this.$refs.firmwareRef) {
							this.$refs.firmwareRef.setData({})
							this.activeKeys.push('firmware')
						}
					})
					break;
			}
		} ,
		_openPriceListModal() {

			this.$refs.servicePriceListModalRef.openModal({
				serviceId: this.service.id,
				serviceNumber: this.service.service_no,
				droneSerial: this.service.droneData.drone.serial_no,
				dronePixhawk: this.service.droneData.drone.pixhawk_serial_no,
				droneModelName: this.service.droneData.model.name,
				customerName: this.service.customer_name,
				customerPhone: this.service.customer_phone,
				customerProvince: this.service.customer_province,
				openDate: this.service.open_date,
				owner: this.service.droneData.owner,
				openBy: this.service.openByData.user.full_name,
				technician: this.service.technicianData ? this.service.technicianData.user.full_name : '',
				repairPartChanges: this.getServiceParts()

			})
		} ,
		getServiceParts() {
			const partsInfo = this.$refs.repairRef && this.$refs.repairRef.$refs.repairPartsRef ? this.$refs.repairRef.$refs.repairPartsRef.getParts() : []
      return this.getPartsInfo(partsInfo)
    },
		handleServiceRemove(key) {
			switch(key) {
				case 'job_repair' :
					this.currentJobRepair = false
					break;
				case 'job_maintenance' :
					this.currentJobMaintenance = false
					break;
				case 'job_firmware' :
					this.currentJobFirmware = false
					break;
			}
		} ,
		initJobs() {
			this.saveError = undefined
			this.currentJobRepair = this.hasJobRepair
			this.currentJobFirmware = this.hasJobFirmware
			this.currentJobMaintenance = this.hasJobMaintenance

			this.$nextTick(() => {
				if (this.hasJobRepair && this.$refs.repairRef) {
					this.$refs.repairRef.setData(this.jobRepair,this.serviceParts)
				}
				if (this.hasJobFirmware && this.$refs.firmwareRef) {
					this.$refs.firmwareRef.setData(this.jobFirmware)
				}
				if (this.$refs.serviceFilesRef) {
					this.$refs.serviceFilesRef.setData(this.serviceFiles)
				}
				if (this.$refs.servicePriceListDocumentFilesRef) {
					this.$refs.servicePriceListDocumentFilesRef.setData(this.servicePriceListDocumentFiles)
				}
				if (this.$refs.serviceConsentDocumentFilesRef) {
					this.$refs.serviceConsentDocumentFilesRef.setData(this.serviceConsentDocumentFiles)
				}
				this.$nextTick(() => {
					// Wait for inspection
					this.oldFormData = this.dumpFormData()
				})
			})
		} ,
		dumpFormData() {
			const formData = {
				service_files: [],
				service_parts: {}
			}
			if (this.currentJobRepair && this.$refs.repairRef) {
				formData.job_repair = this.$refs.repairRef.getData()
				formData.service_parts.repair = this.$refs.repairRef.getPartData()
			}
			if (this.currentJobFirmware && this.$refs.firmwareRef) {
				formData.job_firmware = this.$refs.firmwareRef.getData()
			}
			if (this.$refs.serviceFilesRef) {
				formData.service_files.push({upload_type: 'service', files: this.$refs.serviceFilesRef.getData()})
			}
			if (this.$refs.servicePriceListDocumentFilesRef) {
				formData.service_files.push({upload_type: 'service-price-list', files: this.$refs.servicePriceListDocumentFilesRef.getData()})
			}
			if (this.$refs.serviceConsentDocumentFilesRef) {
				formData.service_files.push({upload_type: 'service-consent-file', files : this.$refs.serviceConsentDocumentFilesRef.getData()})
			}
			return formData
		} ,
		handleReset() {
			if (!this.canUpdateJob)
				return
			const formData = this.dumpFormData()
			if (!this.isDataChange(formData)) {
				scrollTopContent()
				return
			}

			this.$confirm({
				title : this.$t('service_job.action.ask_reset.title') ,
				content : this.$t('service_job.action.ask_reset.message') ,
				okText : this.$t('common.confirm') ,
				maskClosable : true,
				onOk: ()=> {
					this.initJobs()
					scrollTopContent()
				} ,
			})
		} ,
		handleSave() {
			if (!this.canUpdateJob)
				return
			let hasSuccess = true

			if (this.$refs.repairRef) {
				hasSuccess = this.$refs.repairRef.validate() && hasSuccess
			}
			if (!hasSuccess) {
				this.$message.error(this.$t('service_job.action.update.validate'))
				return
			}

			const jobsFormData = this.dumpFormData()
			if (!this.isDataChange(jobsFormData)) {
				this.$message.info(this.$t('service_job.action.update.no_change'))
				return
			}

			this.$confirm({
				title : this.$t('service_job.action.update.confirm.title') ,
				content : this.$t('service_job.action.update.confirm.message') ,
				okText : this.$t('common.confirm') ,
				maskClosable : true,
				onOk: ()=> {
					this.showPageLoading(true)
					// transform preFormData
					const formData = jobsFormData
					axios.put("/api/services/"+this.service.id+"/jobs",formData).then((response)=>{
						this.ignorePreventExit = true
						this.$message.success(this.$t('service_job.action.update.confirm.success',{name:this.service.service_no}))
						this.$router.go()
					}).catch((error) => {
						this.saveError = error
						scrollTopContent()
					}).finally(()=>{
						this.hidePageLoading()
					})
				} ,
			})
		} ,
		preventExit(event) {
			if (this.checkPreventExit()) {
				event.preventDefault()
				event.returnValue = ""
			}
		},
		isDataChange(formData) {
			const change = !_isEqual(this.oldFormData,formData)
			return change
		} ,
		checkPreventExit(formData=null) {
			if (!this.canUpdateJob || this.ignorePreventExit)
				return false
			if(!formData) {
				formData = this.dumpFormData()
			}
			return this.isDataChange(formData)
		} ,
		handlePriceListandPriceSummary(serviceStatus) {
			this.isDraft = serviceStatus === 'new' || serviceStatus === 'in_progress'
			this.$nextTick(()=> {
				this._openPriceListModal()
			})
		}
	} ,
	beforeRouteLeave(routeTo,routeFrom,next) {
		const formData = this.dumpFormData()
		if (this.checkPreventExit(formData)) {
			this.$confirm({
				title : this.$t('service_job.action.ask_leave.title') ,
				content : this.$t('service_job.action.ask_leave.message') ,
				okText : this.$t('common.confirm') ,
				maskClosable : true,
				onOk: ()=> {
					next()
				} ,
			})
		} else {
			next()
		}
	}
}
</script>

<style lang="less" scoped>
.service_job-collapse::v-deep {
	> .ant-collapse-item > .ant-collapse-header {
		font-family: @font-family-title;
		font-size : 1.2em;

		.ant-collapse-extra {
			> .anticon {
				padding : 8px;
				margin : -8px;
			}
		}
	}
}
.service_job-manage-btn {
	float : left;
	.mobile &{
		float : none;
		margin-top : 8px;
		text-align: left;
	}
}
.files-component {
	margin-right: 2rem;
}
.files-upload {
	display: flex;
	flex-direction: row;
	margin-right: 2rem;
}

.service_job-repair-pane::v-deep {
	.ant-collapse-content-box {
		background : @white;
	}
}
.service_job-firmware-pane::v-deep {
	.ant-collapse-content-box {
		background :#e0f2f1; //teal1
	}
}
.service_job-maintenance-pane::v-deep {
	.ant-collapse-content-box {
		background : @yellow-1;
	}
}
.service_job-parts-pane::v-deep {
	.ant-collapse-content-box {
		background : @blue-1;
	}
}
.service_job-files-pane::v-deep {
	.ant-collapse-content-box {
		background : #efebe9;//brown-1;
	}
}

</style>
