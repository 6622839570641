<i18n locale="th" lang="yaml" src="@i18n/service/service-job.th.yaml">
</i18n>
<i18n locale="th" lang="yaml" >
service_repair.field.repair_result_reason.placeholder : "ระบุปัญหาที่เจอคร่าว ๆ"
service_repair_result.reasons.1 : "ไม่สามารถซ่อมได้"
service_repair_result.reasons.2 : "อะไหล่ไม่พอ"
service_repair_result.reasons.3 : "ไม่พบปัญหา ตามลูกค้าแจ้ง"
service_repair_result.reasons.4 : "ลูกค้าต้องการนำเครื่องไปใช้ก่อน"

service_repair_result.reason.display : "( เนื่องจาก {reason} )"

</i18n>

<template>
	<div class="service_repair_result-container">
		<template v-if="canUpdate">
			<div class="service_repair_result-row" style="margin-bottom:24px">
				<label>{{$t('service_repair.field.repair_result')}}</label>
				<div >
					<a-radio-group v-model="repairResultData.repair_result" @change="handleResult">
						<a-radio value="in_progress">{{$tenum('service.repair.result','in_progress')}}</a-radio>
						<a-radio value="success">{{$tenum('service.repair.result','success')}}</a-radio>
						<a-radio value="fail">{{$tenum('service.repair.result','fail')}}</a-radio>
					</a-radio-group>
				</div>
			</div>
			<div class="service_repair_result-row">
				<label>{{$t('service_repair.field.repair_result_reason')}} : </label>
				<div class="service_repair_result-input-block">
					<a-auto-complete
						v-model="repairResultData.repair_result_reason"
						class="reason-input"
						:data-source="reasons"
						:disabled="repairResultData.repair_result != 'fail'"
						:placeholder="$t('service_repair.field.repair_result_reason.placeholder')"
						allow-clear
						auto-focus>
					</a-auto-complete>
				</div>
			</div>
		</template>
		<div v-else class="service_repair_result-row" >
			<span :class="resultDisplayCss">
				{{$tenum('service.repair.result',repairResultData.repair_result)}}
			</span>
			<span v-if="$notEmpty(repairResultData.repair_result_reason)" class="reason-display">
				{{$t('service_repair_result.reason.display',{ reason: repairResultData.repair_result_reason })}}
			</span>
		</div>


	</div>
</template>

<script>
import {Radio,AutoComplete} from "ant-design-vue"
import {replaceDeepProperty,copyDeep} from "@utils/objectUtil"
function _defaultRepairResultData() {
	return {
		repair_result : 'in_progress',
	 	repair_result_reason : undefined,
	}
}
export default {
	components : {
		"a-auto-complete" : AutoComplete,
		"a-radio" : Radio,
		"a-radio-group" : Radio.Group,
	} ,
	props : {
		service : {
			type : null,
			default : () => []
		} ,
		canUpdate : {
			type : Boolean ,
			default : false,
		}
	} ,
	data() {
		return {
			repairResultData : _defaultRepairResultData() ,
		}
	} ,
	computed : {
		reasons() {
			return [
				this.$t('service_repair_result.reasons.1') ,
				this.$t('service_repair_result.reasons.2') ,
				this.$t('service_repair_result.reasons.3') ,
				this.$t('service_repair_result.reasons.4') ,
			]
		} ,
		resultDisplayCss() {
			return [
				'result-display' ,
				{
					'text-success' : this.repairResultData.repair_result == 'success' ,
					'text-error' : this.repairResultData.repair_result == 'fail' ,
				}
			]
		}
	} ,
	methods : {
		setData(data) {
			this.repairResultData = _defaultRepairResultData()
			if (data) {
				replaceDeepProperty(this.repairResultData,data)
			}
		} ,
		getData() {
			const data = copyDeep(this.repairResultData)
			if (data.repair_result != 'fail') {
				data.repair_result_reason = null
			}
			return data
		} ,
		clear() {
			this.repairResultData = _defaultRepairResultData()
		} ,
		handleResult() {
			if (this.repairResultData.repair_result != 'fail') {
				this.repairResultData.repair_result_reason = null
			}
		}
	}
}
</script>

<style lang="less" scoped>
.service_repair_result-row {
	> label {
		display : inline-block;
		color : @info-color;
		margin-bottom : 8px;
	}
}
.result-display {
	margin-bottom : 4px;
	margin-left : 24px;
	font-size : 2em;
	color : @info-color;
	font-family: @font-family-title;
}
.reason-display {
	color : @text-muted;
	margin-left : 32px
}
.reason-input {
	min-width : 275px;
}
.service_repair_result-input-block {
	display : inline-block;
}
</style>
